import React, { memo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import WaitAndAnimate from '../components/waitAndAnimate';

const NotFoundPage = () => {
  const children = [
    () => <h1 className="c-404__title">404</h1>,
    () => <h2 className="c-404__subtitle">Page introuvable</h2>,
    () => (
      <Link className="c-404__link c-button" to="/">
        Page d'accueil
      </Link>
    ),
  ];

  return (
    <div id="content" className="c-404 l-content l-content--centered">
      {children.map((item, i) => (
        <WaitAndAnimate key={i} classNames="fadeup" delay={1000 + 100 * i}>
          {item}
        </WaitAndAnimate>
      ))}
    </div>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default memo(NotFoundPage);
